import { Button, Divider, Grid2 } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { isAutomatedTask, TaskInstance } from "../../Types/TaskInstance";
import { useStatusServicesContext } from "../../Services/TaskStatusServiceContext";
import { classes } from "../../App.Styles";
import TaskPropertyItem from "../Property/TaskPropertyItem";

interface IProps {
  task: TaskInstance;
  onUpdate: (state: { key: string; value: string }[]) => void;
  onCancel: () => void;
}

const TaskState: React.FC<IProps> = ({ task, onUpdate, onCancel }) => {
  const [values, setValues] = useState(task.state);

  const onChangeValue = (key: string, value: string) => {
    setValues((values) =>
      values.map((x) => (x.key == key ? { key: key, value: value } : x)),
    );
  };

  const onCancelChange = () => {
    setValues(task.state);
    onCancel();
  };

  const onSave = () => {
    onUpdate(values);
  };

  const { patchApiTransitions: transitions } = useStatusServicesContext();

  const [isReadOnly, setIsReadOnly] = useState(false);
  useEffect(() => {
    const transitionsForTaskAndStatus =
      transitions[task.taskType][task.activityContext.status];
    if (transitionsForTaskAndStatus === undefined) {
      throw new Error(
        `Task status '${task.taskType}' does not exist for task type '${task.activityContext.status}'`,
      );
    }

    setIsReadOnly(
      transitionsForTaskAndStatus.length === 0 || isAutomatedTask(task),
    );
  }, [transitions, task.activityContext.status]);

  return (
    <React.Fragment>
      <Grid2 className={classes.taskDivider} size={12}>
        <Divider />
      </Grid2>
      <Grid2 container size={12} width={"100%"}>
        {values.map(({ key, value }) => (
          <TaskPropertyItem
            disabled={isReadOnly}
            key={key}
            label={key}
            value={value}
            onChange={(newValue: string) => onChangeValue(key, newValue)}
          />
        ))}
      </Grid2>
      {isReadOnly || (
        <Grid2
          container
          justifyContent="flex-end"
          className={classes.buttonContainer}
          size={12}
        >
          <Button
            className={classNames(classes.button, classes.buttonCancel)}
            onClick={() => onCancelChange()}
          >
            Cancel
          </Button>
          <Button
            className={classNames(classes.button, classes.buttonSave)}
            onClick={() => onSave()}
          >
            Save
          </Button>
        </Grid2>
      )}
    </React.Fragment>
  );
};

export default TaskState;

import React from "react";
import { Alert, Grid2, MenuItem, TextField } from "@mui/material";
import EmailActivityProperties from "./EmailActivityProperties";
import HttpActivityProperties from "./HttpActivityProperties";
import { TaskType } from "../../../Common/Enums/TaskType";
import AireFrameCreateFormActivityProperties from "./AireFrameCreateFormActivityProperties";
import Help from "../../Help/Help";
import IUITaskDefinition from "../../../Common/Interfaces/IUITaskDefinition";
import IHeaderDefinition from "../../../Common/Interfaces/IHeaderDefinition";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import PublishEventActivityProperties from "./PublishEventActivityProperties";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import ActivityPropertiesHeader from "./ActivityPropertiesHeader";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  onRetryChange: (retryOnFailure: boolean) => void;
  context: string;
  task: IUITaskDefinition;
  eventDefinitions: IEventDefinition[];
}

const AutomatedActivityProperties: React.FC<IProps> = ({
  onPropertiesChange,
  onRetryChange,
  context,
  task,
  readOnly,
  eventDefinitions,
}) => {
  const taskType = task.taskType;
  const value = task.activityContext;

  const description = (): string => {
    switch (taskType) {
      case TaskType.Email:
        return "Sends an email when the task is created";
      case TaskType.Http:
        return "Sends an HTTP POST request when the task is created";
      case TaskType.PublishEvent:
        return "Publishes an event when the task is created";
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      <ActivityPropertiesHeader
        taskType={taskType}
        description={description()}
      />
      {taskType === TaskType.AireFrameCreateForm && (
        <Alert severity="warning">
          The <strong>AireFrame - Create Form</strong> task type is outdated and
          should be replaced by the <strong>Form</strong> task type. Existing
          and in-flight workflows will remain unchanged and work as expected,
          but you will no longer be able to select{" "}
          <strong>AireFrame - Create Form</strong> for new task definitions
        </Alert>
      )}
      <Grid2 size={12}>
        <TextField
          select
          disabled={readOnly}
          value={task.retryOnFailure === true ? "true" : "false"}
          label="Retry on Failure"
          fullWidth
          variant="outlined"
          onChange={(e) => onRetryChange(e.target.value === "true")}
          slotProps={{
            input: {
              startAdornment: (
                <Help text="Whether the task should be re-tried per system settings on failure" />
              ),
            },
          }}
        >
          <MenuItem key={"Yes"} value={"true"}>
            Yes
          </MenuItem>
          <MenuItem key={"No"} value={"false"}>
            No
          </MenuItem>
        </TextField>
      </Grid2>
      {taskType === TaskType.Email && (
        <EmailActivityProperties
          onPropertiesChange={onPropertiesChange}
          context={context}
          to={value?.To}
          subject={value?.Subject}
          body={value?.Body}
          readOnly={readOnly}
        />
      )}
      {taskType === TaskType.Http && (
        <HttpActivityProperties
          onPropertiesChange={onPropertiesChange}
          context={context}
          url={value?.Url}
          contentType={value?.ContentType}
          body={value?.Body || JSON.stringify({}, null, "\t")}
          readOnly={readOnly}
          headers={
            value?.Headers
              ? (JSON.parse(value.Headers) as IHeaderDefinition[])
              : []
          }
        />
      )}
      {taskType === TaskType.AireFrameCreateForm && (
        <AireFrameCreateFormActivityProperties
          onPropertiesChange={onPropertiesChange}
          context={context}
          correlationId={value?.CorrelationId}
          keyProp={value?.KeyProp}
          subjectId={value?.SubjectId}
          version={value?.Version}
          readOnly={readOnly}
        />
      )}
      {taskType === TaskType.PublishEvent && (
        <PublishEventActivityProperties
          onPropertiesChange={onPropertiesChange}
          eventKey={value?.EventKey ?? ""}
          eventDefinitions={eventDefinitions}
        />
      )}
    </React.Fragment>
  );
};

export default AutomatedActivityProperties;

import React from "react";
import { Grid2, Typography } from "@mui/material";
import { TaskType } from "../../../Common/Enums/TaskType";
import { TaskTypeNameProvider } from "../../../Common/Providers/TaskTypeNameProvider";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";
import Help from "../../Help/Help";

interface IProps extends IReadOnlyComponentProps {
  taskType: TaskType;
  description: string;
}

const ActivityPropertiesHeader: React.FC<IProps> = ({
  taskType,
  description,
}) => {
  return (
    <Grid2
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction="column"
      size={12}
    >
      <Grid2 size={12}>
        <Typography variant="h6" className={classes.subtitle}>
          {TaskTypeNameProvider(taskType)} Properties
          <Help text={description} />
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default ActivityPropertiesHeader;

import React from "react";
import { Grid2 } from "@mui/material";
import MappableTextField from "../../MappableTextField/MappableTextField";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";

interface IProps extends IReadOnlyComponentProps {
  onPropertiesChange: (value: Record<string, string>) => void;
  formKey: string;
  version: string;
  context: string;
}

const FormActivityProperties: React.FC<IProps> = ({
  onPropertiesChange,
  formKey,
  version,
  context,
  readOnly,
}) => {
  const onChange = (setValue: () => void) => {
    setValue();
    onPropertiesChange({
      key: formKey,
      version: version,
    });
  };

  return (
    <>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Form Key"
          value={formKey}
          placeholder=""
          context={context}
          onChange={(value) => onChange(() => (formKey = value))}
          helpText="The key of the form to create"
          readOnly={readOnly}
          required
        />
      </Grid2>
      <Grid2
        size={{
          md: 6,
          xs: 12,
        }}
      >
        <MappableTextField
          label="Form Version"
          value={version}
          placeholder=""
          context={context}
          onChange={(value) => onChange(() => (version = value))}
          helpText="The version of the form to create. If this is left empty, the latest form version will be used"
          readOnly={readOnly}
        />
      </Grid2>
    </>
  );
};

export default FormActivityProperties;

import { useMemo } from "react";
import { Accordion, AccordionDetails, Grid2 } from "@mui/material";
import IUITaskDefinition from "../../../Common/Interfaces/IUITaskDefinition";
import ITaskDefinition from "../../../Common/Interfaces/ITaskDefinition";
import { EvaluateXPath } from "../../../Common/Helpers/PathEvaluators";
import MappableTextField from "../../MappableTextField/MappableTextField";
import Filters from "../Filters/Filters";
import ContextInput from "../../ContextInput/ContextInput";
import InitialState from "../InitialState/InitialState";
import { TaskType } from "../../../Common/Enums/TaskType";
import Triggers from "../Triggers/Triggers";
import TriggeredUpdates from "../Triggers/TriggeredUpdates";
import IWorkflowDefinition from "../../../Common/Interfaces/IWorkflowDefinition";
import IEventDefinition from "../../../Common/Interfaces/IEventDefinition";
import ITriggerDefinition from "../../../Common/Interfaces/ITriggerDefinition";
import ITriggeredUpdateDefinition from "../../../Common/Interfaces/ITriggeredUpdateDefinition";
import AutomatedActivityProperties from "../ActivityContext/AutomatedActivityProperties";
import { TaskTypeNameProvider } from "../../../Common/Providers/TaskTypeNameProvider";
import StandardTaskProperties from "./StandardTaskProperties";
import DueDate from "../DueDate/DueDate";
import IEventDefinitionGroup from "../../../Common/Interfaces/IEventDefinitionGroup";
import IconOutput from "../../Icons/IconOutput";
import ItemAccordionSummary from "../DefinitionDetail/ItemAccordionSummary";
import DeleteIcon from "@mui/icons-material/Delete";
import { Draggable } from "@hello-pangea/dnd";
import { IReadOnlyComponentProps } from "../../../Interfaces/IReadOnlyComponentProps";
import { classes } from "../../../App.Styles";
import FormActivityProperties from "../ActivityContext/FormActivityProperties";
import ActivityPropertiesHeader from "../ActivityContext/ActivityPropertiesHeader";
import { isAutomatedTaskType } from "../../../Types/TaskInstance";

interface IProps extends IReadOnlyComponentProps {
  tasks: IUITaskDefinition[];
  task: IUITaskDefinition;
  index: number;
  context: string;
  workflowDefinitions: IWorkflowDefinition[];
  eventDefinitions: IEventDefinition[];
  eventDefinitionGroups: IEventDefinitionGroup[];
  workflowDefinitionId: string;
  workflowDefinitionKey: string;
  workflowVersion: number;
  accordionClicked: boolean;
  expandedAccordions: any[];
  onChange: (tasks: ITaskDefinition[]) => void;
  setDeleteOpen: (deleteOpen: boolean) => void;
  setSelectedTask: (task: IUITaskDefinition) => void;
  setAccordionClicked: (clicked: boolean) => void;
  setExpandedAccordions: (
    expandedAccordions: React.SetStateAction<any[]>,
  ) => void;
}

const DraggableTask: React.FC<IProps> = ({
  tasks,
  task,
  index,
  context,
  workflowDefinitions,
  eventDefinitions,
  eventDefinitionGroups,
  workflowDefinitionId,
  workflowDefinitionKey,
  workflowVersion,
  readOnly,
  accordionClicked,
  expandedAccordions,
  onChange,
  setDeleteOpen,
  setSelectedTask,
  setAccordionClicked,
  setExpandedAccordions,
}) => {
  const handleAccordionToggle = (index: number, clicked: boolean) => {
    setAccordionClicked(clicked);
    setExpandedAccordions((prevState) => {
      const newExpandedAccordions = [...prevState];
      newExpandedAccordions[index] = !newExpandedAccordions[index];
      return newExpandedAccordions;
    });
  };

  const onNameChange = (index: number, value: string) => {
    tasks[index].name = value;
    onChange(tasks);
  };

  const onKeyChange = (index: number, value: string) => {
    tasks[index].taskKey = value;
    onChange(tasks);
  };

  const onTypeChange = (index: number, value: TaskType) => {
    tasks[index].taskType = value;

    if (value === TaskType.Manual) {
      tasks[index].activityContext = {};
      tasks[index].retryOnFailure = undefined;
    } else if (!tasks[index].activityContext) {
      tasks[index].activityContext = {};
    }

    if (value !== TaskType.Manual) {
      tasks[index].retryOnFailure = true;
    }

    onChange(tasks);
  };

  const onRetryChange = (index: number, value: boolean) => {
    tasks[index].retryOnFailure = value;
    onChange(tasks);
  };

  const onDescriptionChange = (index: number, value: string) => {
    tasks[index].description = value;
    onChange(tasks);
  };

  const onAssigneeIdChange = (index: number, value: string) => {
    tasks[index].assigneeId = value;
    onChange(tasks);
  };

  const onAssigneeTypeChange = (index: number, value: string) => {
    tasks[index].assigneeType = value;
    onChange(tasks);
  };

  const onOwnerChange = (index: number, value: string) => {
    tasks[index].owner = value;
    onChange(tasks);
  };

  const onFiltersChange = (index: number, filters: string[]) => {
    tasks[index].filters = filters;
    onChange(tasks);
  };

  const onEmittedContextChange = (index: number, emittedContext: string) => {
    tasks[index].emittedContext = emittedContext;
    onChange(tasks);
  };

  const onDueDateChange = (index: number, dueDate: string) => {
    tasks[index].activityContext["Due"] = dueDate;
    onChange(tasks);
  };

  const onStateChange = (index: number, state: Record<string, string>) => {
    tasks[index].state = state;
    onChange(tasks);
  };

  const onExclusiveChange = (index: number, exclusive: boolean) => {
    tasks[index].exclusive = exclusive;
    onChange(tasks);
  };

  const onActivityChange = (
    taskIndex: number,
    notification: Record<string, string>,
  ) => {
    tasks[taskIndex].activityContext = notification;
    onChange(tasks);
  };

  const onTriggersChange = (
    taskIndex: number,
    triggers: ITriggerDefinition[],
  ) => {
    const updatedTasks = tasks.map((task, index) => {
      if (index === taskIndex) {
        return {
          ...task,
          triggers: triggers.map((trigger) => ({ ...trigger })),
        };
      }
      return task;
    });

    onChange(updatedTasks);
  };

  const onTriggeredUpdatesChange = (
    taskIndex: number,
    triggers: ITriggeredUpdateDefinition[],
  ) => {
    tasks[taskIndex].triggeredUpdates = triggers;
    onChange(tasks);
  };

  const generateTaskText = (task: IUITaskDefinition) => {
    const triggerText =
      task.triggers.length === 1
        ? "1 Trigger"
        : `${task.triggers.length} Triggers`;
    const filterText =
      task.filters.length === 1 ? "1 Filter" : `${task.filters.length} Filters`;

    return `${TaskTypeNameProvider(
      task.taskType,
    )}, ${triggerText}, ${filterText}`;
  };

  const triggerEventKeys = useMemo(() => {
    return task.triggers.map((t) => t.selectedEventKey);
  }, [task.triggers]);

  const publishedEventKey = useMemo(() => {
    return task.activityContext?.EventKey;
  }, [task.activityContext]);

  const triggerEventOptions = useMemo(
    () =>
      eventDefinitions.filter(
        (eventDefinition) => eventDefinition.eventKey !== publishedEventKey,
      ),
    [eventDefinitions, publishedEventKey],
  );

  const publishedEventOptions = useMemo(
    () =>
      eventDefinitions.filter(
        (eventDefinition) =>
          !triggerEventKeys.includes(eventDefinition.eventKey),
      ),
    [eventDefinitions, triggerEventKeys],
  );

  const isExpanded = expandedAccordions[index];

  return (
    <Draggable
      key={index}
      draggableId={`draggable-${index}`}
      index={index}
      isDragDisabled={readOnly}
    >
      {(provided) => (
        <Accordion
          id="task"
          key={index}
          expanded={isExpanded}
          slotProps={{
            transition: {
              timeout: accordionClicked ? 300 : 0,
              unmountOnExit: true,
            },
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div onClick={() => handleAccordionToggle(index, true)}>
            <ItemAccordionSummary
              key={task.id + task.name}
              name={EvaluateXPath(task.name, context)}
              placeholder={`Task ${index + 1}`}
              onAction={() => {
                setSelectedTask(task);
                setDeleteOpen(true);
              }}
              actionIcon={() => (
                <DeleteIcon data-testid={`delete-${task.taskKey}`} />
              )}
              extraText={generateTaskText(task)}
              readOnly={readOnly}
              draggable
              dragHandleProps={provided.dragHandleProps}
            />
          </div>
          <AccordionDetails>
            <Grid2
              container
              spacing={2}
              alignItems="center"
              justifyContent="flex-start"
            >
              <StandardTaskProperties
                task={task}
                context={context}
                onNameChange={(name) => onNameChange(index, name)}
                onKeyChange={(key) => onKeyChange(index, key)}
                onTypeChange={(type) => onTypeChange(index, type)}
                onOwnerChange={(owner) => onOwnerChange(index, owner)}
                onExclusiveChange={(exclusive) =>
                  onExclusiveChange(index, exclusive)
                }
                readOnly={readOnly}
              />
              {!isAutomatedTaskType(task.taskType) && (
                <>
                  <Grid2
                    sx={{
                      display: { xs: "none", sm: "block" },
                    }}
                    size={{
                      md: 6,
                    }}
                  />
                  <Grid2
                    size={{
                      md: 6,
                      xs: 12,
                    }}
                  >
                    <MappableTextField
                      label="Assignee ID"
                      context={context}
                      value={task.assigneeId}
                      onChange={(value) => onAssigneeIdChange(index, value)}
                      placeholder="/xml/assignee-id/text()"
                      helpText="An expression to specify the assignee for the task."
                      readOnly={readOnly}
                    />
                  </Grid2>
                  <Grid2
                    size={{
                      md: 6,
                      xs: 12,
                    }}
                  >
                    <MappableTextField
                      label="Assignee Type"
                      context={context}
                      value={task.assigneeType}
                      onChange={(value) => onAssigneeTypeChange(index, value)}
                      placeholder="/xml/assignee-type/text()"
                      helpText="An expression to specify the assignee type for the task. If using with AireFrame this should be Unknown, User, or Subject."
                      readOnly={readOnly}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <MappableTextField
                      label="Description"
                      context={context}
                      value={task.description}
                      onChange={(value) => onDescriptionChange(index, value)}
                      multiline
                      placeholder="/xml/description/text()"
                      helpText="An expression to specify the description of the task."
                      readOnly={readOnly}
                    />
                  </Grid2>
                </>
              )}
              {isAutomatedTaskType(task.taskType) && !!task.activityContext && (
                <AutomatedActivityProperties
                  onPropertiesChange={(activity) =>
                    onActivityChange(index, activity)
                  }
                  onRetryChange={(retry) => onRetryChange(index, retry)}
                  context={context}
                  task={task}
                  readOnly={readOnly}
                  eventDefinitions={publishedEventOptions}
                />
              )}
              {task.taskType === TaskType.Form && (
                <>
                  <ActivityPropertiesHeader
                    taskType={task.taskType}
                    description="Creates a form when the task is started and will also withdraw the form if the task is cancelled"
                  />
                  <FormActivityProperties
                    onPropertiesChange={(activity) =>
                      onActivityChange(index, activity)
                    }
                    context={context}
                    formKey={task.activityContext?.key}
                    version={task.activityContext?.version}
                    readOnly={readOnly}
                  />
                </>
              )}
              <Grid2 size={12}>
                <Grid2
                  container
                  spacing={1}
                  className={classes.itemChild}
                  width={"100%"}
                  pl={1}
                  pt={1}
                >
                  <Grid2 size={12}>
                    <Triggers
                      triggers={task.triggers}
                      onChange={(triggers: ITriggerDefinition[]) =>
                        onTriggersChange(index, triggers)
                      }
                      context={context}
                      workflowDefinitions={workflowDefinitions}
                      eventDefinitions={triggerEventOptions}
                      eventDefinitionGroups={eventDefinitionGroups}
                      workflowDefinitionId={workflowDefinitionId}
                      workflowVersion={workflowVersion}
                      parentTaskKey={task.taskKey}
                      readOnly={readOnly}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <TriggeredUpdates
                      triggers={task.triggeredUpdates ?? []}
                      onChange={(triggers: ITriggeredUpdateDefinition[]) =>
                        onTriggeredUpdatesChange(index, triggers)
                      }
                      workflowDefinitions={workflowDefinitions}
                      eventDefinitions={eventDefinitions}
                      eventDefinitionGroups={eventDefinitionGroups}
                      workflowDefinitionId={workflowDefinitionId}
                      workflowVersion={workflowVersion}
                      parentTaskKey={task.taskKey}
                      readOnly={readOnly}
                    />
                  </Grid2>
                  {!isAutomatedTaskType(task.taskType) && (
                    <>
                      <Grid2 size={12}>
                        <DueDate
                          dueDate={task.activityContext["Due"]}
                          index={index}
                          taskKey={task.taskKey}
                          workflowDefinitionKey={workflowDefinitionKey}
                          workflowDefinitionVersion={workflowVersion}
                          readOnly={readOnly}
                          onChange={onDueDateChange}
                        />
                      </Grid2>
                      <Grid2 size={12}>
                        <InitialState
                          onChange={(value) => onStateChange(index, value)}
                          state={task.state}
                          context={context}
                          readOnly={readOnly}
                        />
                      </Grid2>
                    </>
                  )}
                  <Grid2 size={12}>
                    <Filters
                      filters={task.filters}
                      onChange={(value) => onFiltersChange(index, value)}
                      context={context}
                      readOnly={readOnly}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <ContextInput
                      onChange={(value) => onEmittedContextChange(index, value)}
                      value={task.emittedContext}
                      name="Output Context"
                      icon={() => <IconOutput />}
                      readOnly={readOnly}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default DraggableTask;

import * as React from "react";
import { jsonClient } from "hippity";
import { useEffect, useState } from "react";
import { TaskStatus } from "../Common/Enums/TaskStatus";
import { TaskType } from "../Common/Enums/TaskType";

const defaultPatchApiTransitions = {
  [TaskType.Manual]: {},
  [TaskType.Email]: {},
  [TaskType.Http]: {},
  [TaskType.AireFrameCreateForm]: {},
  [TaskType.PublishEvent]: {},
  [TaskType.Form]: {},
};

export interface IStatusServicesContext {
  patchApiTransitions: Record<
    TaskType,
    Partial<Record<TaskStatus, TaskStatus[]>>
  >;
}

export const statusServicesContext =
  React.createContext<IStatusServicesContext>({
    patchApiTransitions: defaultPatchApiTransitions,
  });

export const useStatusServicesContext = (): IStatusServicesContext =>
  React.useContext(statusServicesContext);

export const StatusServicesContextProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const [patchApiTransitions, setPatchApiTransitions] = useState<
    Record<TaskType, Partial<Record<TaskStatus, TaskStatus[]>>>
  >(defaultPatchApiTransitions);

  useEffect(() => {
    (async function () {
      setPatchApiTransitions(await GetPatchApiTransitions());
    })();
  }, []);

  const GetPatchApiTransitions = async (): Promise<
    Record<TaskType, Partial<Record<TaskStatus, TaskStatus[]>>>
  > => {
    return (await jsonClient.$get("/Api/TaskStatus/v2/Transitions")) as Record<
      TaskType,
      Partial<Record<TaskStatus, TaskStatus[]>>
    >;
  };

  return (
    <statusServicesContext.Provider value={{ patchApiTransitions }}>
      {children}
    </statusServicesContext.Provider>
  );
};

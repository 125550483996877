import { Moment } from "moment";
import { TaskStatus } from "../Common/Enums/TaskStatus";
import { TaskType } from "../Common/Enums/TaskType";

export type TaskInstance = {
  id: string;
  subjectId: string;
  correlationId: string;
  workflowDefinitionVersion: number;
  name: string;
  description: string;
  assigneeId: string;
  state: { key: string; value: string }[];
  taskType: TaskType;
  activityContext: {
    status: TaskStatus;
  };
  updated: Moment;
  created: Moment;
  lastRun?: Moment;
} & (
  | {
      taskType: TaskType.Manual;
      activityContext: {
        due?: Moment;
      };
    }
  | {
      taskType: TaskType.Email;
      activityContext: {
        to: string;
        subject: string;
        body?: string;
        retryCount?: number;
      };
    }
  | {
      taskType: TaskType.Http;
      activityContext: {
        url: string;
        contentType: string;
        body: string;
        headers: string;
        retryOnFailure: boolean;
        retryCount?: number;
      };
    }
  | {
      taskType: TaskType.AireFrameCreateForm;
      activityContext: {
        keyProp: string;
        version?: string;
        subjectId: string;
        userId?: string;
        retryCount?: number;
      };
    }
  | {
      taskType: TaskType.PublishEvent;
      activityContext: {
        eventKey?: string;
        retryCount?: number;
      };
    }
  | {
      taskType: TaskType.Form;
      activityContext: {
        key: string;
        instanceId?: string;
        version?: number;
        due?: Moment;
      };
    }
);

const automatedTaskTypes = [
  TaskType.Email,
  TaskType.Http,
  TaskType.AireFrameCreateForm,
  TaskType.PublishEvent,
];

export function isAutomatedTaskType(taskType: TaskType) {
  return automatedTaskTypes.includes(taskType);
}

export function isAutomatedTask(task: TaskInstance): task is TaskInstance & {
  taskType:
    | TaskType.Email
    | TaskType.Http
    | TaskType.AireFrameCreateForm
    | TaskType.PublishEvent;
} {
  return automatedTaskTypes.includes(task.taskType);
}
